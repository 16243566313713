import React from "react"
import SEO from "../components/utility/SEO"
import {graphql} from "gatsby";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import ImageDialog from "../components/ImageDialog";
import Container from "@material-ui/core/Container";

const useStyles = makeStyles(() => ({
  imageDialog: {
    width: '100%',
    maxWidth: 'unset'
  }
}));

const Partners = ({data}) => {
  const classes = useStyles();

  if (!data || !data.prismicServices) {
    return ''
  }

  const {data: pageData} = data.prismicServices

  return (
    <>
      <SEO title="Υπηρεσίες"/>
      <Container maxWidth={"lg"}>
        <Grid container justify={"space-around"} style={{marginBottom: 8}}>
          {pageData.services_image_group && pageData.services_image_group.map((deal, index) => {
            return (
              <Grid item style={{padding: 24}} xs={12} md={6} key={index}>
                <ImageDialog
                  image={deal.services_image}
                  className={classes.imageDialog}
                />
              </Grid>
            )
          })}
        </Grid>
      </Container>
    </>
  )
}

export default Partners

export const PartnersPageQuery = graphql`
  query PartnersPage {
    prismicServices {
      data {
        services_image_group {
          services_image {
            url
            fluid {
              srcWebp
              srcSetWebp
              srcSet
              src
              sizes
              base64
              aspectRatio
            }
          }
        }
      }
    }
  }
`